.PortfolioList::-webkit-scrollbar{
    width: 7px;
  }
/* Track */
.PortfolioList::-webkit-scrollbar-track {
    background: #293847;
  }
  
  /* Handle */
  .PortfolioList::-webkit-scrollbar-thumb {
    background: #7C8996;
    border-radius: 2vw;

  }
  
  /* Handle on hover */
  .PortfolioList::-webkit-scrollbar-thumb:hover {
    background: #596A7A;
  }