/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=13-0u0kchjn5UsX9mnEe-dKl3co */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */

.color-primary-0 { color: #415364 }	/* Main Primary color */
.color-primary-1 { color: #7C8996 }
.color-primary-2 { color: #596A7A }
.color-primary-3 { color: #293847 }
.color-primary-4 { color: #192D40 }



/* As RGBa codes */

.rgba-primary-0 { color: rgba( 65, 83,100,1) }	/* Main Primary color */
.rgba-primary-1 { color: rgba(124,137,150,1) }
.rgba-primary-2 { color: rgba( 89,106,122,1) }
.rgba-primary-3 { color: rgba( 41, 56, 71,1) }
.rgba-primary-4 { color: rgba( 25, 45, 64,1) }



/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */