.PortfolioDetailsImageContainer{
    width: 100%;
    overflow: hidden;
    height: 90vh;
    color: white;
    font-size: 90px;
    display: grid;
}

.PortfolioDetailsImage{
    grid-area: 1/1;
    object-fit:cover;
    height: 100%;
    width: 100%;
    transition: background-image 1s ease-in-out;
    background-size: cover;
}



.PortfolioImageTextContainer{
    grid-area: 1/1;
    background-image:
    linear-gradient(to bottom, rgba(25, 45, 64, 0.2), rgba(25, 45, 64, 0.73)); 
    width: 100%;
    overflow-x: hidden;
    height: 90vh;
    background-size: cover;
    color: white;
    display: table;
}
.PortfolioImageText{
    display: table-cell;
    vertical-align:bottom;
    padding-left: 1vw;
    font-size: 8vw;
}

@media only screen and (max-width: 600px) {
    .PortfolioImageText{
        font-size: 20vw;
    }
}


.PorfolioDetailsParagraphs{
    font-size: 25px;
}
.PorfolioDetailsParagraphs a{
    color: #7C8996;
}
.PorfolioDetailsParagraphs a:visited{
    color: #596A7A;
}

.PortfolioDescriptionTagContainer{
    padding: 1% 0;
    overflow-x: auto;
    overflow-y: hidden;
}


.PortfolioDescriptionTagContainer::-webkit-scrollbar{
    height: 5px;
  }
/* Track */
.PortfolioDescriptionTagContainer::-webkit-scrollbar-track {
    background: #293847;
  }
  
  /* Handle */
  .PortfolioDescriptionTagContainer::-webkit-scrollbar-thumb {
    background: #7C8996;
    border-radius: 1vw;

  }
  
  /* Handle on hover */
  .PortfolioDescriptionTagContainer::-webkit-scrollbar-thumb:hover {
    background: #596A7A;
  }

.PortfolioDescriptionTag{
    background-color: #596A7A;
    color: #192D40;
    display: inline;
    font-weight:bold;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 3%;
    padding-left: 3%;
    border-radius: 20px;
    margin-right: 2%;
    font-size: 20px;
    white-space: nowrap;
}

.PortfolioDetailsTextContainer{
    width: 100%;
    background-color: #192D40;
}

.PortfolioDetailsText{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5vh;
    min-height: 95vh;
    margin-left: 15%;
    margin-right: 15%;
    background-color: #293847;
    color:#7C8996;
    overflow-y: hidden;
}