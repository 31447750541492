.FilterItem{
    color: #7C8996;
    font-size: 20px;
}
.FilterHeading{
    color: #7C8996;
}
.FilterContainer{
    background: linear-gradient(90deg, rgba(25,45,64,1) 0%, rgba(28,38,47,1) 58%);
    display:inline-block; 
    width:19%; 
    height:100%; 
    vertical-align:top;
    padding-left: 1%;
}