.EntryContainer {
    background-color: #192D40;
    color: #7C8996;
    width: 45%;
    margin:2.5%;
    display: inline-block;
    text-decoration: none;
    /*padding: 10px;*/
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
/*
.EntryContainer:hover{
    width:50%;
    margin: 0;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
*/

.EntryDescription {
    background-color: #596A7A;
    color: #192D40;
    display: inline;
    font-weight:bold;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 3%;
    padding-left: 3%;
    border-radius: 20px;
    margin-right: 2%;
}

.EntryDescriptionContainer{
    white-space: nowrap;
    overflow: hidden;
    padding-top: 1%;
    padding-bottom: 1%;
}

.EntryImageContainer{
    height:20vw;   
}

.EntryImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size:cover;
}
.EntryTextContainer{
    padding: 5%;
    background-color: #192D40;

}
.EntryTitle{
    margin:0;
    margin-bottom: 5%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}


@media only screen and (max-width: 600px) {
    .EntryContainer{
        width: 95%;
    }
    .EntryImageContainer{
        height: 50vw;
    }
}
