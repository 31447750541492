.NavigationBar{
    width:100%;
    height:10vh;
    display:block;
    background-color:#192D40;
}
.NavigationBarItem{
    width:15%;
    display:inline-flex;
    background-color:#192D40;
    height:100%;
    justify-content:center;
    line-height:9vh;
    font-size:3vw;
    height: 9vh;
    border: 0.5vh solid #293847;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    color: #7C8996;
    text-decoration: none;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .NavigationBarItem{
        font-size: 5vw;
        width:23%;
    }
    .NavigationBarItem:hover{
        content: none;
    }
}

.NavigationBarItem:hover{
    background-color:#415364;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    width:20%;
    letter-spacing: 5px;
}