.FooterContainer{
    width: 100%;
    height: 10vh;
    background-color:#192D40;
    display: block;
}
.FooterIcons{
    float: right;
    height:100%
}

.FooterIcon{
    color: #7C8996;
    cursor: pointer;
    margin: 0 auto;
    margin-right: 5vw;
    transition-duration: 0.2s;
    height:100%
}
.FooterIcon:hover{
    transition-duration: 0.2s;
    color: #293847;
}